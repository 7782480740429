import React from "react";
import cookie from "react-cookies";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Alert,
  Dropdown,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import { FaEllipsisV, FaChevronCircleLeft, FaChevronCircleRight, FaPen, FaUser } from "react-icons/fa";

import { renderToString } from "react-dom/server";

import FullCalendar from "@fullcalendar/react";
import DatePicker from "react-datepicker";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";

import moment from "moment";
import $ from "jquery";

import BookingModal from "./BookingModal.jsx";

// import { toMoment } from '@fullcalendar/moment'; // only for formatting
import momentTimezonePlugin from "@fullcalendar/moment-timezone";
import { api } from "../shared/functions/api";

//  timegrid = good for viewing a day's appointments in detail, cars at top. More than 7 or 8 and you gotta scroll horizontally on phones

const resource_sort_order = {
  1: 3, // van midsize
  2: 3, // car midsize
  4: 5, // ??  trailer
  5: 5, // ?? accessory
  6: 4, // van economy
  7: 1, // car economy
  8: 4, // truck eco
  9: 4, // van large
  10: 2, // car large
  11: 4, // truck large
  12: 1, // electric car
};

class Calendar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      view_type: "resourceTimeGridDay",
      bookingModalOpen: false,

      booking_modal_state: null,
      booking_date: null,
      booking_bookable: null,

      editingEvent: null,
      resource_filter: [],
      events: [],
      vehiclesavailability: [],
      vehicleAvails: [],
      filteredEvents: [],
      isOpen: { 0: false, 1: false, 2: false }, // dropdown menus
    };

    this.handleDateClick = this.handleDateClick.bind(this);
    this.handleEventClick = this.handleEventClick.bind(this);
    this.handleBook = this.handleBook.bind(this);
    this.handleCancelModal = this.handleCancelModal.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleCancelBooking = this.handleCancelBooking.bind(this);
  }

  componentDidMount() {
    this.load();
    this.loadUsers();
    this.loadAvailability();

    this.setState({ resource_filter: this.props.user.resource_filter || {} });
    this._calendar = React.createRef();

    setTimeout(() => {
      if (this._calendar.current) {
        const cal = this._calendar.current.getApi();
        const date = this.formatDate(cal.getDate().toISOString());
        this.setState({ selected_date: date });
        this.showHelp();
      }
    }, 3000);
  }

  showHelp() {
    const isOpen = this.state.isOpen;
    const user = this.props.user;
    const new_user = !user.resource_filter || !user.resource_filter[this.props.selected_calendar];
    if (user.permission && user.permission.indexOf("ADMIN_USER") === -1 && new_user) {
      isOpen[2] = true;
      this.setState({ isOpen });
    }
  }

  formatDate(date) {
    // js date
    return moment(date).format("YYYY-MM-DD");
  }

  componentWillReceiveProps(nextProps) {
    this.load();
  }

  load() {
    const postData = {
      action: "getEvents",
      id: this.props.uid,
      jwt: this.props.jwt,
    };

    $.ajax({
      url: cookie.load("url") + "api.php",
      type: "POST",
      data: postData,
    })
      .done((response) => {
        const data = JSON.parse(response);
        if (data.error) {
          console.log(data.error);
          return false;
        }
        console.log("load ajax data:", data);
        this.buildEvents(data);
      })
      .fail((err) => {
        console.log("err", err);
      });
  }

  //This is a workaround for stale state in the BookingModal
  //React implementation in this app is both outdated and suffers antipatterns

  async getEventsForModalPopup() {
    const postData = {
      action: "getEvents",
      id: this.props.uid,
      jwt: this.props.jwt,
    };

    const result = await $.ajax({
      url: cookie.load("url") + "api.php",
      type: "POST",
      data: postData,
    });
    //console.log("postData getEvents result ", result);
    let data = JSON.parse(result);
    this.buildEvents(data);
    //return data;
  }

  //this.load
  buildEvents(data) {
    let events = [];
    let eventsByResource = {};
    const calendar = this.props.calendars[this.props.selected_calendar];

    if (calendar.bookables) {
      data.forEach((e) => {
        const event = {
          id: e.id,
          //  title: e.subject,
          start: e.starttime,
          end: e.endtime,
          resourceId: e.bookableobject,
          backgroundColor: calendar.bookables[e.bookableobject] ? calendar.bookables[e.bookableobject].color : "blue",
          subject: e.subject,
          uid: e.uid,
          vehicle_used_id: e.vehicle_used_id,
          event_type: e.event_type,
          description: e.description,
          estimated_kms: e.estimated_kms,
        };
        events.push(event);

        if (!eventsByResource[e.bookableobject]) eventsByResource[e.bookableobject] = [];
        eventsByResource[e.bookableobject].push(event);
      });
    }

    this.setState({
      events: events,
      eventsByResource: eventsByResource,
    });
  }

  async loadAvailability() {
    const vehiclesavailability = await api(`vehiclesavailability`);

    let vehicleArray = [];
    let vehicleAvails = [];
    console.log("vehiclesavailability", vehiclesavailability);
    for (var vehicle of vehiclesavailability) {
      //console.log(vehicle.vehicle_id, vehicle.startTime >= vehicle.endTime);
      if (vehicle.startTime >= vehicle.endTime) continue;

      vehicle.extendedProps = vehicle;
      vehicleArray.push(vehicle);
      vehicleAvails.push([vehicle.vehicle_id][vehicle]);

      if (!vehicleAvails[vehicle.vehicle_id]) {
        vehicleAvails[vehicle.vehicle_id] = [];
      }

      vehicleAvails[vehicle.vehicle_id].push(vehicle);
    }
    //console.log("Array", vehicleArray);

    this.setState({
      vehiclesavailability: vehicleArray,
      vehicleAvails: vehicleAvails,
    });
  }

  loadUsers() {
    const postData = {
      action: "getUsers",
      id: this.props.uid,
      jwt: this.props.jwt,
    };

    $.ajax({
      url: cookie.load("url") + "api.php",
      type: "POST",
      data: postData,
    })
      .done((response) => {
        const data = JSON.parse(response);
        if (data.error) {
          console.log("Api data error ß", data.error);
          return false;
        }
        this.setState({
          users: data,
        });
      })
      .fail((err) => {
        console.log("load api err", err);
      });
  }

  handleAllowEvent(info) {
    //console.log("allow event", info);
    return false;
  }

  async handleDateClick(info) {
    await this.getEventsForModalPopup();
    //console.log("Date Click ", info);
    if (info.jsEvent !== undefined) {
      if (info.jsEvent.target.classList.contains("fc-bgevent")) {
        console.log("background event");
        return;
      }
    }

    const calendar = this.props.calendars[this.props.selected_calendar];
    // if using 'book vehicle' button, use first bookable. TODO: get last booked vehicle instead
    let booking_bookable,
      calendar_clicked = true;
    if (info.resource) booking_bookable = calendar.bookables[info.resource.id];
    else {
      booking_bookable = null;
      calendar_clicked = false;
    }

    this.setState(
      {
        booking_modal_state: "editing",
        booking_date: info.date,
        booking_bookable: booking_bookable, // calendar.bookables[info.resource.id]
        calendar_clicked: calendar_clicked,
      },
      () => {
        this.setState({ bookingModalOpen: true });
      }
    );
  }

  async handleEventClick(info) {
    //ßconsole.log("Info click event: ", info, info.event.id);
    await this.getEventsForModalPopup();
    let event = this.state.events.find(({ id }) => id === info.event.id);
    let user = this.props.user;
    //console.log("Events: ", this.state.events);
    //console.log("Event: ", event);
    //console.log("User: ", user);
    if (event) {
      if (info?.event?.rendering === "background") return;
      if (info?.event?.rendering === "inverse-background") return false;

      if (user.permission.includes("ADMIN_USER") || event.uid === user.id) {
        this.setState({
          editingEvent: event,
          bookingModalOpen: true,
          booking_modal_state: "editing",
          booking_date: new Date(event.start),
          calendar_clicked: true,
        });
      }
    } else {
      alert("Event no longer exists. Likely removed by user");
    }
  }

  async handleBook(data) {
    let self = this;
    const action = this.state.editingEvent ? "edit" : "add";

    if (this.state.editingEvent) data.event_id = this.state.editingEvent.id;

    data.id = this.props.uid;
    data.jwt = this.props.jwt;

    try {
      const newEventResponse = await api(`events/${action}`, "POST", data);
      this.setState({
        booking_modal_state: "notices",
      });
      this.load();
    } catch (err) {
      console.log("Error", err);
    }
  }

  handleBookOld(data) {
    // console.log('booking', data);

    let self = this;
    data.action = this.state.editingEvent ? "editEvent" : "createEvent";

    if (this.state.editingEvent) data.event_id = this.state.editingEvent.id;

    data.id = this.props.uid;
    data.jwt = this.props.jwt;

    $.post(cookie.load("url") + "api.php", data, (data) => {
      data = JSON.parse(data);
      if (data.result === "success") {
        this.setState({
          booking_modal_state: "notices",
        });
        this.load();
      } else alert("Error: " + data.error);
    });
  }

  handleCancelBooking(event_id, cancelation_type) {
    const post_data = {
      action: "cancelEvent",
      id: this.props.uid,
      jwt: this.props.jwt,
      event_id: event_id,
      cancelation_type: cancelation_type,
      editor_id: this.props.user.id,
    };
    $.post(cookie.load("url") + "api.php", post_data, (data) => {
      data = JSON.parse(data);
      if (data.result === "success") {
        this.setState({
          bookingModalOpen: false,
          booking_modal_state: null,
          editingEvent: null,
        });
        this.load();
      } else console.log("Handle Cancel Booking", data);
    });
  }

  handleCancelModal() {
    this.setState({ bookingModalOpen: false, booking_modal_state: null, editingEvent: null });
  }

  handleChangeModalState(new_state) {
    this.setState({ booking_modal_state: new_state });
    if (new_state === "complaint") this.setState({ editingEvent: null });
  }

  toggleOpen(i) {
    let isOpen = this.state.isOpen;
    console.log("isopen", isOpen);
    isOpen[i] = !isOpen[i];
    this.setState({ isOpen }, () => {
      if (i === 1 && !isOpen[i]) window.location.reload(); // workaround for not reloading the user object after saving resource filter
    });
  }

  toggleResourceFilter(id) {
    let calendar_id = this.props.selected_calendar;
    let resource_filter = this.state.resource_filter || {};
    if (resource_filter === "null") resource_filter = {};
    if (!resource_filter[calendar_id]) resource_filter[calendar_id] = [];
    const index = resource_filter[calendar_id].indexOf(id);
    if (index > -1) resource_filter[calendar_id].splice(index, 1);
    else resource_filter[calendar_id].push(id);
    this.setState({ resource_filter }, () => {
      this.saveResourceFilter();
    });
  }

  filterSelect(which) {
    let calendar_id = this.props.selected_calendar;
    let resource_filter = this.state.resource_filter || {};
    if (resource_filter === "null") resource_filter = {};
    if (!resource_filter[calendar_id]) resource_filter[calendar_id] = [];
    if (which === "all") {
      resource_filter[calendar_id] = [];
      this.setState({ resource_filter }, () => {
        this.saveResourceFilter();
      });
    }
    if (which === "none") {
      for (let id in this.props.calendars[this.props.selected_calendar].bookables) {
        if (!resource_filter[calendar_id]) resource_filter[calendar_id] = [];
        resource_filter[calendar_id].push(id);
      }
      resource_filter[calendar_id] = [...new Set(resource_filter[calendar_id])];
      this.setState({ resource_filter }, () => {
        this.saveResourceFilter();
      });
    }
  }

  saveResourceFilter() {
    const filterJSON = JSON.stringify(this.state.resource_filter);
    const postData = {
      action: "saveResourceFilter",
      resource_filter: filterJSON,
      id: this.props.uid,
      jwt: this.props.jwt,
    };

    $.ajax({
      url: cookie.load("url") + "api.php",
      type: "POST",
      data: postData,
    }).done((response) => {
      const data = JSON.parse(response);
      if (data.error) {
        console.log("Data error", data.error);
        return false;
      }
    });
  }

  handleCalendarChange(which, to) {
    to = to || this.state.view_type;
    const _calendar = this._calendar.current.getApi();
    if (which === "next") _calendar.next();
    if (which === "prev") _calendar.prev();
    if (which === "today") _calendar.today();
    if (which === "view") _calendar.changeView(to);

    const date = this.formatDate(_calendar.getDate().toISOString());

    this.setState({ selected_date: date, view_type: to });
  }

  handleDateChange(date) {
    const _calendar = this._calendar.current.getApi();
    _calendar.gotoDate(date);
    this.setState({ selected_date: date });
  }

  renderEvent(info) {
    if (info?.event?.extendedProps?.daysOfWeek) return info.el;
    if (info.view.type === "resourceTimeGridDay") {
      const me = this.props.user;
      const users = this.state.users;
      const event = info.event;
      const event_user = users.find((user) => user.id === info.event.extendedProps.uid);
      if(!event_user) {
        console.log("event_user not found. .uid for event:", info.event.extendedProps.uid);
        return info.el;
      }
      const icon =
        me.permission.includes("ADMIN_USER") || me.id === event_user.id
          ? renderToString(
              <span className="float-right event-edit-button">
                <FaPen />
              </span>
            )
          : "";
      let time = info.el.firstChild.firstChild.firstChild.innerHTML;

      time = this.cleanEventTime(time, info.event);
      info.el.firstChild.firstChild.innerHTML = time + icon;
      let raw_name,
        name = "";

      if (me.id === event_user.id) {
        name = renderToString(
          <div className="event-username no-small-screen">
            <FaUser /> ME
          </div>
        );
      } else if (me.permission.includes("ADMIN_USER")) {
        raw_name = event_user.accountingname ? event_user.accountingname : event_user.displayname;
        name = renderToString(
          <div className="event-username no-small-screen">
            <FaUser /> {raw_name}
          </div>
        );
      }

      const subject = event.extendedProps.subject
        ? renderToString(<div className="event-subject no-small-screen">{event.extendedProps.subject}</div>)
        : "";
      info.el.innerHTML = info.el.innerHTML + name + subject;
    }
    return info.el;
  }

  cleanEventTime(time, event) {
    const bits = time.split(" - ");
    let arr = [];
    if (this.isOnCurrentView(event.start, event)) arr.push(bits[0]);
    arr.push("-");
    if (this.isOnCurrentView(event.end, event)) arr.push(bits[1]);
    return renderToString(<span>{arr.join("")}</span>);
  }

  isOnCurrentView(date, event) {
    return moment(date).isAfter(moment(event._calendar.view.activeStart)) && moment(date).isBefore(moment(event._calendar.view.activeEnd));
  }

  render() {
    //console.log("availi", this.state.vehiclesavailability);
    const calendar = this.props.calendars[this.props.selected_calendar];
    const filter = this.state.resource_filter ? this.state.resource_filter[this.props.selected_calendar] : null;
    let bookables = [];
    let bookables_filtered = [];
    if (this.props.calendars && this.props.calendars[this.props.selected_calendar] && calendar.bookables && this.state.users) {
      for (let i in calendar.bookables) {
        const bk = calendar.bookables[i];

        if (filter) {
          if (!filter.includes(bk.id))
            bookables_filtered.push({
              id: bk.id,
              title: bk.nickname,
              resourceText: null,
              order: resource_sort_order[bk.vehicle_type],
            });
        } else
          bookables_filtered.push({
            id: bk.id,
            title: bk.nickname,
            resourceText: null,
            order: resource_sort_order[bk.vehicle_type],
          });

        bk.order = resource_sort_order[bk.vehicle_type];

        bookables.push(bk);
      }

      bookables.sort((a, b) => (a.order > b.order ? 1 : -1));
      bookables_filtered.sort((a, b) => (a.order > b.order ? 1 : -1));
      //console.log("bookables", bookables);
      const resource_filter = this.state.resource_filter[this.props.selected_calendar] || [];

      const disabled_day = this.state.view_type != "resourceTimeGridDay";
      const disabled_month = this.state.view_type != "resourceTimelineMonth";
      const format = this.state.view_type === "resourceTimeGridDay" ? "MMM D YYYY" : "MMM YYYY";
      const no_vehicles_selected = filter && bookables.length === filter.length;

      return (
        <span>
          {this.state.isOpen[2] && (
            <Card>
              <CardHeader style={{ backgroundColor: "#95ff6a" }}>
                <strong>Tips on using the new booking calendar</strong>
              </CardHeader>
              <CardBody>
                <ul>
                  <li>
                    Click on the <strong>date</strong> below to open a mini-calendar to select a date, or click <FaChevronCircleLeft /> or{" "}
                    <FaChevronCircleRight /> to change dates
                  </li>
                  <li>Click anywhere below a vehicle name on the calendar to create a booking at that time</li>
                  {resource_filter.length === 0 && (
                    <li>
                      <Button size="sm" color="primary" onClick={() => this.toggleOpen(1)}>
                        Click Here
                      </Button>{" "}
                      to limit the number of vehicles that show in the calendar
                    </li>
                  )}
                </ul>
              </CardBody>
            </Card>
          )}

          <Card className="mt-0 calendar-container" color="dark">
            <CardHeader>
              <span className="float-right">
                <Dropdown
                  isOpen={this.state.isOpen[0]}
                  toggle={() => {
                    this.toggleOpen(0);
                  }}
                  direction="down"
                >
                  <DropdownToggle color="white">
                    <span style={{ color: "white", marginLeft: 30 }} className="d-none d-md-inline-block">
                      Options
                    </span>
                    <FaEllipsisV style={{ color: "white", marginRight: -15 }} />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => this.toggleOpen(1)}>Select Vehicles</DropdownItem>

                    <DropdownItem header>Display</DropdownItem>
                    <DropdownItem onClick={() => this.handleCalendarChange("today")}>Today</DropdownItem>
                    <DropdownItem disabled={disabled_day} onClick={() => this.handleCalendarChange("view", "resourceTimelineMonth")}>
                      Month View
                    </DropdownItem>
                    <DropdownItem disabled={disabled_month} onClick={() => this.handleCalendarChange("view", "resourceTimeGridDay")}>
                      Day View
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </span>

              <Button className="small calendar-nav-button left" color="primary" onClick={() => this.handleCalendarChange("prev")}>
                <FaChevronCircleLeft />
              </Button>

              <DatePicker
                calendar
                selected={moment(this.state.selected_date).toDate()}
                onChange={this.handleDateChange}
                dateFormat="MMM d, yyyy"
                id="datetime"
                className="form-control"
                customInput={<DateButton date={moment(this.state.selected_date).format(format)} />}
              />

              <Button className="small calendar-nav-button right" color="primary" onClick={() => this.handleCalendarChange("next")}>
                <FaChevronCircleRight />
              </Button>
              <span className="float-right d-none d-md-inline-block" style={{ margin: 7 }}>
                Calendar for <strong>{this.props.calendars[this.props.selected_calendar].calendar_title}</strong>{" "}
              </span>
            </CardHeader>
            <CardBody className="bg-light">
              <div className="inline-tip">
                <strong>Click below</strong> to create a booking
              </div>
              <Row>
                <Col xs={12}>
                  <FullCalendar
                    timeZone="local"
                    defaultView="resourceTimeGridDay"
                    plugins={[resourceTimelinePlugin, resourceTimeGridPlugin, interactionPlugin, listPlugin, dayGridPlugin, momentTimezonePlugin]}
                    resources={bookables_filtered}
                    dateClick={this.handleDateClick}
                    eventClick={this.handleEventClick}
                    selectAllow={this.handleAllowEvent}
                    contentHeight="auto"
                    aspectRatio={1}
                    events={[...this.state.events, ...this.state.vehiclesavailability]}
                    header={{
                      left: "",
                      right: "",
                    }}
                    eventTimeFormat={{
                      // like '14:30:00'
                      hour: "numeric",
                      minute: "2-digit",
                      omitZeroMinute: true,
                      omitZeroHour: true,
                      meridiem: "narrow",
                    }}
                    schedulerLicenseKey="0911742788-fcs-1573055291"
                    resourceRender={function (info) {
                      const bookable = calendar.bookables[info.resource.id];

                      info.el.style.backgroundColor = "black";
                      info.el.style.color = "white";
                      info.el.style.fontSize = "11px";
                      info.el.title = bookable.name;

                      const address = <div className="header-address">{bookable.address}</div>;

                      if (info.view.type === "resourceTimeGridDay")
                        info.el.innerHTML =
                          renderToString(
                            <div className="car-color-swatch" style={{ backgroundColor: bookable.color }}>
                              &nbsp;
                            </div>
                          ) +
                          info.el.innerHTML +
                          renderToString(address);
                    }}
                    eventRender={(info) => {
                      //console.log("eventRender: ", info);
                      return this.renderEvent(info);
                    }}
                    datesRender={function (info) {
                      // console.log(info.el);
                    }}
                    ref={this._calendar}
                  />
                </Col>
              </Row>
            </CardBody>

            <BookingModal
              {...this.props}
              id="booking-modal"
              show={this.state.bookingModalOpen}
              modalState={this.state.booking_modal_state}
              handleBook={this.handleBook}
              handleCancel={this.handleCancelModal}
              editingEvent={this.state.editingEvent}
              bookables={this.props.calendars[this.props.selected_calendar].bookables}
              selected_bookable={this.state.booking_bookable}
              vehicleAvails={this.state.vehicleAvails}
              selected_date={this.state.booking_date}
              eventsByResource={this.state.eventsByResource}
              announcements={this.props.announcements_by_bookable}
              user={this.props.user}
              calendar_clicked={this.state.calendar_clicked}
              changeState={(new_state) => this.handleChangeModalState(new_state)}
              cancelBooking={this.handleCancelBooking}
              users={this.state.users}
            />

            <Modal isOpen={this.state.isOpen[1]} className="modal-lg primary" style={{ maxWidth: 446 }}>
              <ModalHeader>Select which vehicles show on calendar</ModalHeader>
              <ModalBody>
                <p>
                  <strong>Please choose the 3 or 4 vehicles you use the most</strong> and unselect the rest. You can change this at any time.
                </p>

                {Object.keys(bookables).map((e, key) => {
                  const item = bookables[e];
                  const selected = !filter || !filter.includes(item.id);
                  return (
                    <Card key={key} style={{ marginBottom: 4 }}>
                      <CardBody style={{ padding: 8 }}>
                        <Row>
                          <Col xs={2}>
                            <input type="checkbox" checked={selected} onChange={() => this.toggleResourceFilter(item.id)} />
                          </Col>
                          <Col xs={10}>
                            &nbsp;<Badge>{item.nickname}</Badge>&nbsp;
                            <small>{item.name}</small>
                            <div>
                              <small style={{ color: "#7e7edc" }}>{item.address}</small>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  );
                })}
              </ModalBody>
              <ModalFooter>
                <div style={{ width: "100%" }}>
                  select <button onClick={() => this.filterSelect("all")}>all</button> &nbsp;
                  <button onClick={() => this.filterSelect("none")} style={{ marginRight: 4 }}>
                    none
                  </button>
                  {no_vehicles_selected && <Badge color="warning">Select at least 1</Badge>}
                  <Button className="float-right" color="primary" onClick={() => this.toggleOpen(1)} disabled={no_vehicles_selected}>
                    Done
                  </Button>
                </div>
              </ModalFooter>
            </Modal>
          </Card>
        </span>
      );
    } else return <Alert>Loading... ..</Alert>;
  }
}

export default Calendar;

/*  Full Calendar:
- PayPal Order Number: 7JY70665MC883741W (this is not your license key)
- Scheduler Version Number: 4.3.1
- License Type: Scheduler Single Website
- License Key: 0911742788-fcs-1573055291
*/

class DateButton extends React.Component {
  render() {
    const { onClick, date } = this.props;
    return (
      <Button color="primary" className="date-button" onClick={onClick}>
        {date}
      </Button>
    );
  }
}
